<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="false"
                title="Visit Calendar"
            />
        </div>

        <div class="app-calendar overflow-hidden border">
            <div class="row no-gutters">
                <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body pb-0" v-if="!loading">
                            <FullCalendar :options='calendarOptions' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
        <AddOrEditVisitPlanModal
            v-if="$store.state.isModalOpen"
            :editData="singlePlan"
        />
    </div>
</template>
  
<script>
import TitleButton from '@/components/atom/TitleButton';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import handleCrm from '@/services/modules/crm/crm.js'
import Loader from '@/components/atom/LoaderComponent'
import { getCurrentDateTime } from '@/services/utils/global.js'
import { inject } from 'vue';
import AddOrEditVisitPlanModal from '@/components/molecule/company/crm/visit-plan/AddOrEditVisitPlanModal.vue';
const { fetchPlanActivity, fetchSingleVisitPlan } = handleCrm();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
  
export default {
    components: {
        FullCalendar,
        TitleButton,
        Loader,
        AddOrEditVisitPlanModal
    },
    data() {
        return {
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                initialView: 'dayGridMonth',
                initialEvents: [],
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents
            },
            currentEvents: [],
            loading: false,
            singlePlan: null
        }
    },

    methods: {
        async getVisitPlanActivity () {

            const query = this.getQuery();
            this.loading = true

            await fetchPlanActivity(query).then(res => {
                if (!res.status){
                    this.calendarOptions.initialEvents = []
                    return
                }
                this.calendarOptions.initialEvents = res.data.map(item => {
                    return {
                        id: item.visit_plan_id,
                        title: (item.short_description + ' - ' + item.dealer_name),
                        start: getCurrentDateTime(item.start_time),
                        end: getCurrentDateTime(item.end_time)
                    }
                });
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })
        },
        getQuery() {
            let query = `?company_id=${this.$route.params.companyId}`;
            if(this.$route.query.from === 'my-visit-schedule') query += `&my_plan=1`;
            return query;
        },
        handleWeekendsToggle() {
            this.calendarOptions.weekends = !this.calendarOptions.weekends
        },
        handleDateSelect(selectInfo) {
            //TODO:Some logic will go here in future
        },
        async handleEventClick(clickInfo) {
            const planId = clickInfo.event.id;
            if(! planId) return;
            
            this.loading = true;

            await fetchSingleVisitPlan(this.getQuery(), planId).then((res) => {
                if(!res.status) return showError(res.message);
                this.singlePlan = res.data;
                this.$store.state.isModalOpen = true;
            }).finally(() => {
                this.loading = false
            })
        },
        handleEvents(events) {
            this.currentEvents = events
        }
    },

    mounted() {
        this.getVisitPlanActivity();
    }
  }
  </script>

